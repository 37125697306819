import React from 'react'
import Layout from '../../components/Layout'

export default () => (
  <Layout>
    <section className="section">
      <div className="container column is-8">
        <div className="content">
          <h1>Thank you for getting in touch!</h1>
          <p>We hope to get back to you in a few days.</p>
          <br></br>
          <figure className="image thank-you-image">
            <img src="/img/girls_on_trip.jpg" alt="girls on school trip"/>
          </figure>
        </div>
      </div>
    </section>
  </Layout>
)
